import React, { Fragment } from "react";
import Hero from "../components/Hero";

const HomePage = () => {
  return (
    <div className="flex justify-center items-center ">
      <Hero />
    </div>
  );
};

export default HomePage;
